import React from 'react'
import styled from '@emotion/styled'
import {colors} from 'styles'

const FooterBackground = styled.div({
  width: '100%',
  height: 'min(50vh, 540px)',
  overflow: 'hidden',
  position: 'absolute',
  zIndex: -1,
  bottom: 0,
  borderBottom: `1px solid ${colors.primary[10]}`,
})

const FooterGradient: React.FunctionComponent = () => (
  <FooterBackground
    style={{
      background: `linear-gradient(to top, ${colors.primary[0]} 40%, rgba(255,255,255,1) 100%`,
    }}
  />
)

export default FooterGradient
