import React from 'react'
import {css, ClassNames} from '@emotion/core'
import {Link} from 'gatsby'
import styled from '@emotion/styled'
import {padding, typography, margin, colors, breakpoint} from 'styles'
import {trackOutboundLink} from 'analytics'
import SupportLink from './SupportLink'

interface Props {
  siteTitle: string
}

const LogoContainer = styled.div({
  ...padding.x(24),
  ...padding.y(24),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  [breakpoint.smallAndUp]: {
    flexDirection: 'row',
  },
})

const CopyrightContainer = styled.div({
  ...padding.x(24),
  ...padding.y(16),
  ...typography.caption,
  background: '#F6F9F8',
  borderTop: '1px solid #D9D9D9',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#32483D',
  flexDirection: 'column',
  textAlign: 'center',
  [breakpoint.mediumAndUp]: {
    flexDirection: 'row',
  },
})

const NavList = styled.ul({
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  [breakpoint.smallAndUp]: {
    flexDirection: 'row',
  },
})

const NavListItem = styled.li({
  ':not(:last-child)': {
    ...margin.bottom(16),
  },
  [breakpoint.smallAndUp]: {
    ':not(:last-child)': {
      ...margin.bottom(0),
      ...margin.right(24),
    },
  },
})

const logo = css({
  ...margin.bottom(16),
  display: 'block',
  fontSize: '1.125rem',
  fontWeight: 'bold',
  color: colors.primary[50],
  textDecoration: 'none',
  textTransform: 'lowercase',
  [breakpoint.smallAndUp]: {
    fontSize: '1.5rem',
    ...margin.right(16),
    ...margin.bottom(0),
  },
})

const navLink = css({
  ...typography.caption,
  textDecoration: 'none',
  fontWeight: 700,
  color: colors.text,
  transition: 'color 150ms linear',
  ':not(:last-child)': {
    ...margin.bottom(16),
  },
  ':hover': {
    color: colors.primary[50],
  },
})

const legalLink = css({
  color: '#32483D',
})

const Footer = ({siteTitle}: Props) => (
  <footer>
    <LogoContainer>
      <h2>
        <Link to="/" css={logo}>
          {siteTitle}
        </Link>
      </h2>
      <nav>
        <NavList>
          <NavListItem>
            <Link to="/features/" css={navLink}>
              Features
            </Link>
          </NavListItem>
          <NavListItem>
            <Link to="/pricing/" css={navLink}>
              Pricing
            </Link>
          </NavListItem>
          <NavListItem>
            <ClassNames>
              {({css}) => <SupportLink className={css(navLink)} />}
            </ClassNames>
          </NavListItem>
        </NavList>
      </nav>
    </LogoContainer>
    <CopyrightContainer>
      <p
        css={{
          ...margin.bottom(16),
          [breakpoint.mediumAndUp]: {...margin.bottom(0)},
        }}
      >
        Built by{' '}
        <a
          href="https://substantial.com"
          css={{color: '#EE2747'}}
          onClick={() =>
            trackOutboundLink('Substantial', 'https://substantial.com')
          }
        >
          Substantial
        </a>{' '}
        in Seattle, WA
      </p>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          [breakpoint.smallAndUp]: {
            flexDirection: 'row',
          },
        }}
      >
        <p
          css={{
            ...margin.right(16),
            ...margin.bottom(16),
            [breakpoint.smallAndUp]: {
              ...margin.bottom(0),
            },
          }}
        >
          © {new Date().getFullYear()} Substantial Inc
        </p>
        <ul css={{listStyle: 'none', display: 'flex'}}>
          <li css={{':not(:last-child)': {...margin.right(16)}}}>
            <Link to="/terms/" css={legalLink}>
              Terms of Service
            </Link>
          </li>
          <li>
            <Link to="/privacy/" css={legalLink}>
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </CopyrightContainer>
  </footer>
)

export default Footer
