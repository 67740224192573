import React from 'react'
import {trackOutboundLink} from 'analytics'
import useSiteMetadata from 'hooks/useSiteMetadata'

interface Props {
  className?: string
}

const SupportLink: React.FunctionComponent<Props> = ({className, children}) => {
  const {
    appUrls: {supportUrl},
  } = useSiteMetadata()
  return (
    <a
      href={supportUrl}
      className={className}
      onClick={() => trackOutboundLink('Support', supportUrl)}
    >
      {React.Children.count(children) == 0 ? 'Support' : children}
    </a>
  )
}

export default SupportLink
