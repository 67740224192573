declare global {
  interface Window {
    gtag: (
      command: 'config' | 'set' | 'event',
      ...params: any[]
    ) => void | undefined
  }
}

export const trackOutboundLink = (name: string, url: string) => {
  if (!window.gtag) {
    return
  }

  const callback = () => {
    if (document.location !== null) {
      document.location.href = url
    }
  }

  window.gtag('event', name, {
    event_category: 'Outside Links',
    transport_type: 'beacon',
    event_callback: callback,
  })
  setTimeout(callback, 500)
}

export const trackDemoEvent = (name: string, label?: string) => {
  if (!window.gtag) {
    return
  }

  window.gtag('event', name, {
    event_category: 'Demo',
    event_label: label,
  })
}
