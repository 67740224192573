import {CSSObject} from '@emotion/styled'

export const display: CSSObject = {
  fontSize: '3.157rem',
  lineHeight: 1.15,
  fontWeight: 700,
  letterSpacing: '-0.025em',
}

export const headline1: CSSObject = {
  fontSize: '2.665rem',
  lineHeight: 1.25,
  fontWeight: 700,
  letterSpacing: '-0.01em',
}

export const headline2: CSSObject = {
  fontSize: '2rem',
  lineHeight: 1.25,
  fontWeight: 700,
  letterSpacing: '-0.01em',
}

export const headline3: CSSObject = {
  fontSize: '1.5rem',
  lineHeight: 1.25,
  fontWeight: 700,
  letterSpacing: '-0.01em',
}

export const headline4: CSSObject = {
  fontSize: '1.125rem',
  lineHeight: 1.5,
  fontWeight: 700,
}

export const body: CSSObject = {
  fontSize: '1.125rem',
  lineHeight: 1.5,
  fontWeight: 400,
}

export const caption: CSSObject = {
  fontSize: '0.844rem',
  lineHeight: 1.33,
  fontWeight: 400,
  letterSpacing: '0.01em',
}

export const micro: CSSObject = {
  fontSize: '0.6875rem',
  lineHeight: 1.2,
  fontWeight: 700,
  letterSpacing: '0.02em',
  textTransform: 'uppercase',
}
