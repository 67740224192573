export const primary = {
  0: '#effaf5',
  10: '#c2f6de',
  20: '#95efc6',
  30: '#6de5af',
  40: '#4dd797',
  50: '#33c481',
  60: '#20ab6a',
  70: '#128b51',
  80: '#096438',
  90: '#04391f',
  100: '#010d07',
}

export const text = '#010d07'
export const textLight = '#516159'

export const red = '#F23D3D'
export const lightGrey = '#E8E8E8'

export const trelloBlue = '#3173bb'
