import React from 'react'
import globalStyles from 'styles/globalStyles'
import {Global} from '@emotion/core'
import Header from 'components/Header'
import {Helmet} from 'react-helmet'
import Footer from 'components/Footer'
import useSiteMetadata from 'hooks/useSiteMetadata'
import HeaderGradient from 'components/HeaderGradient'
import FooterGradient from 'components/FooterGradient'

const Layout: React.FunctionComponent = ({children}) => {
  const {title: siteTitle, description: siteDescription} = useSiteMetadata()

  return (
    <>
      <Global styles={globalStyles} />
      <Helmet
        defaultTitle={`${siteTitle}: A Trello Power-Up for epics and related cards`}
        titleTemplate={`%s - ${siteTitle}`}
        htmlAttributes={{lang: 'en'}}
        meta={[{name: 'description', content: siteDescription}]}
      />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        <HeaderGradient />
        <Header siteTitle={siteTitle} />
        <div css={{flex: '1 1 auto'}}>{children}</div>
        <FooterGradient />
        <Footer siteTitle={siteTitle} />
      </div>
    </>
  )
}

export default Layout
