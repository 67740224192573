import 'ress'
import 'styles/fonts/fonts.css'
import css from '@emotion/css'
import {colors} from './'

export default css`
  body {
    font-family: Akkurat, Helvetica, Arial, sans-serif;
    color: ${colors.text};
  }

  *:focus {
    outline-color: ${colors.primary[50]};
  }
`
