import {useStaticQuery, graphql} from 'gatsby'
import {SiteMetadataQuery} from 'landing-gatsby-graphql-generated'
import invariant from 'tiny-invariant'

export type SiteMetadata = ReturnType<typeof useSiteMetadata>
const useSiteMetadata = () => {
  const data = useStaticQuery<SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          appUrls {
            billingSupportUrl
            enablePowerUpUrl
            enterpriseContactUrl
            siteUrl
            supportUrl
          }
        }
      }
    }
  `)

  const {title, description, appUrls} = data.site?.siteMetadata || {}
  invariant(appUrls, 'expected appUrls')
  const {
    siteUrl,
    supportUrl,
    billingSupportUrl,
    enablePowerUpUrl,
    enterpriseContactUrl,
  } = appUrls

  invariant(title, 'expected title')
  invariant(description, 'expected description')
  invariant(supportUrl, 'expected support url')
  invariant(siteUrl, 'expected site url')
  invariant(billingSupportUrl, 'expected billingSupport url')
  invariant(enablePowerUpUrl, 'expected enablePowerup url')
  invariant(enterpriseContactUrl, 'expected enterpriseContact url')

  return {
    title,
    description,
    appUrls: {
      siteUrl,
      supportUrl,
      billingSupportUrl,
      enablePowerUpUrl,
      enterpriseContactUrl,
    },
  } as const
}

export default useSiteMetadata
