import React from 'react'
import {css, ClassNames} from '@emotion/core'
import {Link} from 'gatsby'
import styled from '@emotion/styled'

import {colors, margin, padding, breakpoint, typography} from 'styles'
import SupportLink from './SupportLink'

interface Props {
  siteTitle: string
}

const HeaderWrapper = styled.header({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  ...padding(24),
})

const logo = css({
  ...margin.right(8),
  fontSize: '1rem',
  fontWeight: 'bold',
  color: colors.primary[50],
  textDecoration: 'none',
  textTransform: 'lowercase',
  [breakpoint.xsmallAndUp]: {
    ...margin.right(16),
    fontSize: '1.25rem',
  },
  [breakpoint.smallAndUp]: {
    fontSize: '1.5rem',
  },
})

const NavList = styled.ul({
  listStyle: 'none',
  display: 'flex',
})

const NavListItem = styled.li({
  ':not(:last-child)': {
    ...margin.right(8),
    [breakpoint.xsmallAndUp]: {
      ...margin.right(16),
    },
    [breakpoint.smallAndUp]: {
      ...margin.right(36),
    },
  },
})

const navLink = css({
  ...typography.caption,
  [breakpoint.xsmallAndUp]: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
  },
  textDecoration: 'none',
  fontWeight: 'bold',
  color: colors.text,
  transition: 'color 150ms linear',
  ':hover': {
    color: colors.primary[50],
  },
})

const activeNavLink = css({
  color: colors.primary[50],
})

const Header = ({siteTitle}: Props) => (
  <HeaderWrapper>
    <span css={{whiteSpace: 'nowrap'}}>
      <Link to="/" css={logo}>
        {siteTitle}
      </Link>
    </span>
    <nav>
      <NavList>
        <NavListItem>
          <ClassNames>
            {({css}) => (
              <Link
                to="/features/"
                css={navLink}
                activeClassName={css(navLink, activeNavLink)}
              >
                Features
              </Link>
            )}
          </ClassNames>
        </NavListItem>
        <NavListItem>
          <ClassNames>
            {({css}) => (
              <Link
                to="/pricing/"
                css={navLink}
                activeClassName={css(navLink, activeNavLink)}
              >
                Pricing
              </Link>
            )}
          </ClassNames>
        </NavListItem>
        <NavListItem>
          <ClassNames>
            {({css}) => <SupportLink className={css(navLink)} />}
          </ClassNames>
        </NavListItem>
      </NavList>
    </nav>
  </HeaderWrapper>
)

export default Header
